<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		<article class="article-container">
			<div class="f-ali-items-center">
				<p class="m-right-05rem_dis">2024.01.12</p>
				<div class="tag-diary">diary</div>
			</div>
			<h3 class="m-bottom-2rem">2024年もよろしくお願いします</h3>
			<div>
				明けましておめでとうございます！雨地屋しろーです。<br>
				皆様お元気で新年をお迎えのことと存じます。<br>
				昨年は冬コミをはじめ、諸々お世話になりました。<br>
				今年もどうぞ宜しくお願い申し上げます！！<br><br>

				<img style="max-width: 100%" src="../../assets/news/04/01.png"><br><br><br>

				※ギャラリーページに新年イラストの全体図置いてます。よかったら見てね！！<br>
				<router-link to="/gallery">＞Gallery</router-link>
			
			</div>
			<NewsShare />
		</article>
		<div class="f-jus-space-around m-top-2rem">
			<router-link id="homeopen" to="/news/02">
				<div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
					<span class="bt-typeA-border-inner font-E1">Prev →</span>
				</div>
			</router-link>
		</div>
		<div class="f-ali-jus-center m-top-1rem">
			<router-link id="homeopen" to="/news">
				<div class="bt-typeA bt-typeA-border m-rl-auto">
					<span class="bt-typeA-border-inner font-E1">Back To List ></span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import NewsShare from './News_Share.vue'

export default {
	name: 'News_02',
	components: {
		NewsShare,
	},
	data () {
		return {
		breadcrumbs: [
		{
			text: 'Home',
			disabled: false,
			to: '/',
			exact: true,
		},
		{
			text: 'News',
			disabled: false,
			to: '/news',
			exact: true,
		},
		{
			text: '2024年もよろしくお願いします',
			disabled: true,
			to: '',
		},
		],
		}
	},
	mounted: function() {
		this.scrollanime_js()
		this.tweet()
	},
}
</script>