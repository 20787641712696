<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		<article class="article-container">
			<div class="f-ali-items-center">
				<p class="m-right-05rem_dis">2023.04.04</p>
				<div class="tag-info">info</div>
			</div>
			<h3 class="m-bottom-2rem">サークル「ねばーランド.F」活動開始しました</h3>
			<div>
				どうも皆さまはじめまして。<br>
				イラストレーターの「<span class="co-brown">雨地屋(うちや)しろー</span>」と申します！<br>
				この度、イラスト制作活動のサークル「ねばーランド.F」を設立しました！<br><br><br>

				今まではTwitterやpixivで二次創作イラストの投稿を中心に活動していたのですが
				イラスト制作に関する活動に本格的に取り組みたいと考えサークルを設立することにしました。<br>
				いつもイラストを観てくださっている皆様が今まで以上に楽しんでいただけるようなもの
				を制作していきたいと考えておりますのでどうぞよろしくお願いいたします！！<br><br><br>

				<h4 class="co-brown">活動方針・やりたいこととか</h4>
				サークルを作ってどんなことをしたいのかについて、<del>(忘れないようn)</del>決意表明としてここに書き記しておこうと思います。<br><br>

				<h5 class="co-brown">・イラスト制作のお仕事</h5>
				本日よりイラストに関するお仕事の受付を開始しました！<br>
				本記事の公開時点(2023年4月4日)では<a href="https://coconala.com/users/3864504" target="_blank" rel="noopener noreferrer">Coconala</a>と当サイトの<router-link to="/contact">お問合せフォーム</router-link>より受け付けておりますので是非お気軽にご相談くださいませ！<br>
				(見積もりやご相談のみでもOKです!)<br><br>
				主にアニメ調の女の子のイラストを得意としております。詳細は<a href="https://coconala.com/users/3864504" target="_blank" rel="noopener noreferrer">Coconala</a>のページに記載しておりますので是非ご覧ください！<br>
				過去のイラストは本サイトの<router-link to="/gallery">Galleryページ</router-link>や<a href="https://www.pixiv.net/users/15823703" target="_blank" rel="noopener noreferrer">pixiv</a>から確認いただけます！<br><br>

				<!-- <router-link to="/works">>Works (お仕事関連の情報など)<br></router-link> -->
				<router-link to="/contact">>Contact (お問合せフォーム)<br></router-link>
				<router-link to="/gallery">>Gallery (過去の制作物)<br></router-link>
				<a href="https://www.pixiv.net/users/15823703" target="_blank" rel="noopener noreferrer">>pixiv (過去の制作物)</a><br><br>

				<h5 class="co-brown">・同人誌制作</h5>
				サークル活動と言ったらこれっていうぐらいには定番ですね！<br>
				私はアズレンのノーチラスがイチ推しなので<del>布教の為に</del>ノーチラス中心のアズレン本を出したいところ...!<br><br>

				<h5 class="co-brown">・ゲーム制作</h5>
				これは企画書制作からメンバー集めまでやることが多いので実行可能な段階までもっていくのはまだまだ先になりそう...orz<br>
				ですが1番やりたい事なのでいつか実現できるように努力していきたいと思います！<br><br><br>

				長くなってしまいましたが初めてのご挨拶はこの辺で〆にしておこうと思います。<br>
				みなさま、どうぞお見知りおきのほどよろしくお願いします！！<br><br><br><br>

				雨地屋しろー
			</div>
			<NewsShare />
		</article>
		<div class="f-jus-space-around m-top-2rem">
			<router-link id="homeopen" to="/news/02">
				<div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
					<span class="bt-typeA-border-inner font-E1">← Next</span>
				</div>
			</router-link>
			<!-- <div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
				<span class="bt-typeA-border-inner font-E1">Prev →</span>
			</div> -->
		</div>
		<div class="f-ali-jus-center m-top-1rem">
			<router-link id="homeopen" to="/news">
				<div class="bt-typeA bt-typeA-border m-rl-auto">
					<span class="bt-typeA-border-inner font-E1">Back To List ></span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import NewsShare from './News_Share.vue'

export default {
	name: 'News_01',
	components: {
		NewsShare,
	},
	data () {
		return {
		breadcrumbs: [
		{
			text: 'Home',
			disabled: false,
			to: '/',
			exact: true,
		},
		{
			text: 'News',
			disabled: false,
			to: '/news',
			exact: true,
		},
		{
			text: 'サークル「ねばーランド.F」活動開始しました',
			disabled: true,
			to: '',
		},
		],
		}
	},
	mounted: function() {
		this.scrollanime_js()
		this.tweet()
	},
}
</script>