<template>
	<section id="News" class="section">
		<h2 id="news-index" class="font-E3 text-center scroll-fadein index-anime"><span>News</span></h2>
		<VerticalLine />
		<div class="d-flex_dis sa sa--up">
			<router-link class="hover-flash main-news-window-container" v-bind:to="news[0].link">
				<div class="main-news-window-info">
					<div class="main-news-window-info-text co-white">
						<p>{{news[0].date}}</p>
						<p>{{news[0].title}}</p>
					</div>
					<div class="main-news-window-info-tag">
						<div v-if="news[0].tag.includes('dmy')" class="tag-dmy co-white tr-duration-05s">dmy</div>
						<div v-if="news[0].tag.includes('info')" class="tag-info co-white tr-duration-05s">info</div>
						<div v-if="news[0].tag.includes('event')" class="tag-event co-white tr-duration-05s">event</div>
						<div v-if="news[0].tag.includes('game')" class="tag-info co-white tr-duration-05s">game</div>
					</div>
				</div>
				<div v-bind:class="news[0].mainthum" class="main-news-window-img-container overflow-hidden f-ali-jus-center tr-duration-05s"></div>
			</router-link>
			<div class="f-ali-jus-center-dir-column m-top-1rem_mob">
				<h3 class="co-brown f-weight-normal">-最新情報-</h3>
				<div class="m-top-1rem">
					<router-link class="m-bottom-05rem" v-bind:to="news[1].link">
						<div class="m-bottom-05rem m-top-05rem p-rl-1rem">
							<div class="d-flex">
								<p class="fs-0_8rem co-black">{{news[1].date}}</p>
								<div class="f-ali-items-center">
									<div v-if="news[1].tag.includes('dmy')" class="tag-dmy-small">dmy</div>
									<div v-if="news[1].tag.includes('info')" class="tag-info-small">info</div>
									<div v-if="news[1].tag.includes('event')" class="tag-event-small">event</div>
									<div v-if="news[1].tag.includes('game')" class="tag-info-small">game</div>
									<div v-if="news[1].tag.includes('diary')" class="tag-diary-small">diary</div>
								</div>
							</div>
							<p class="fs-0_8rem co-black">{{news[1].title}}</p>
						</div>
					</router-link>
					<div class="main-news-line"></div>
					<router-link class="m-bottom-05rem" v-bind:to="news[2].link">
						<div class="m-bottom-05rem m-top-05rem p-rl-1rem">
							<div class="d-flex">
								<p class="fs-0_8rem co-black">{{news[2].date}}</p>
								<div class="f-ali-items-center">
									<div v-if="news[2].tag.includes('dmy')" class="tag-dmy-small">dmy</div>
									<div v-if="news[2].tag.includes('info')" class="tag-info-small">info</div>
									<div v-if="news[2].tag.includes('event')" class="tag-event-small">event</div>
									<div v-if="news[2].tag.includes('game')" class="tag-info-small">game</div>
									<div v-if="news[2].tag.includes('diary')" class="tag-diary-small">diary</div>
								</div>
							</div>
							<p class="fs-0_8rem co-black">{{news[2].title}}</p>
						</div>
					</router-link>
					<div class="main-news-line"></div>
					<router-link class="m-bottom-05rem" v-bind:to="news[3].link">
						<div class="m-bottom-05rem m-top-05rem p-rl-1rem">
							<div class="d-flex">
								<p class="fs-0_8rem co-black">{{news[3].date}}</p>
								<div class="f-ali-items-center">
									<div v-if="news[3].tag.includes('dmy')" class="tag-dmy-small">dmy</div>
									<div v-if="news[3].tag.includes('info')" class="tag-info-small">info</div>
									<div v-if="news[3].tag.includes('event')" class="tag-event-small">event</div>
									<div v-if="news[3].tag.includes('game')" class="tag-info-small">game</div>
									<div v-if="news[3].tag.includes('diary')" class="tag-diary-small">diary</div>
								</div>
							</div>
							<p class="fs-0_8rem co-black">{{news[3].title}}</p>
						</div>
					</router-link>
					<div class="main-news-line"></div>
					<router-link class="m-bottom-05rem" v-bind:to="news[4].link">
						<div class="m-bottom-05rem m-top-05rem p-rl-1rem">
							<div class="d-flex">
								<p class="fs-0_8rem co-black">{{news[4].date}}</p>
								<div class="f-ali-items-center">
									<div v-if="news[4].tag.includes('dmy')" class="tag-dmy-small">dmy</div>
									<div v-if="news[4].tag.includes('info')" class="tag-info-small">info</div>
									<div v-if="news[4].tag.includes('event')" class="tag-event-small">event</div>
									<div v-if="news[4].tag.includes('game')" class="tag-info-small">game</div>
									<div v-if="news[4].tag.includes('diary')" class="tag-diary-small">diary</div>
								</div>
							</div>
							<p class="fs-0_8rem co-black">{{news[4].title}}</p>
						</div>
					</router-link>
				</div>
			</div>
		</div>
		<div class="f-ali-jus-center m-top-2rem sa sa--up">
			<router-link id="" to="/gallery">
				<div class="bt-typeA bt-typeA-border m-rl-auto">
					<span class="bt-typeA-border-inner font-E1">View more ></span>
				</div>
			</router-link>
		</div>
	</section>
</template>

<script>
import VerticalLine from '../VerticalLine.vue'

export default {
	name: 'MainNews',
	components: {
		VerticalLine,
	},
	data: () => ({
		isActive: false,
		news : [
			// 【メモ】新しいものニュースを上から順に追加していく
			{
				title:'コミックマーケット103参加情報について',
				date:'2023.12.01',
				tag:['info','event'],
				mainthum:'mthum-2',subthum:'sthum-1',
				link:'/news/02',
				emergent: false,
			},
			{
				title:'2024年もよろしくお願いします',
				date:'2024.01.12',
				tag:['diary'],
				mainthum:'mthum-2',subthum:'sthum-1',
				link:'/news/04',
				emergent: false,
			},
			{
				title:'冬コミ＆2023年もありがとうございました',
				date:'2023.12.31',
				tag:['diary'],
				mainthum:'mthum-2',subthum:'sthum-1',
				link:'/news/03',
				emergent: false,
			},
			{
				title:'コミックマーケット103参加情報について',
				date:'2023.12.01',
				tag:['info','event'],
				mainthum:'',subthum:'',
				link:'/news/02',
				emergent: false,
			},
			{
				title:'サークル「ねばーランド.F」活動開始しました',
				date:'2023.4.4',
				tag:['info'],
				mainthum:'mthum-1',subthum:'sthum-1',
				link:'/news/01',
				emergent: false,
			},
			{
				title:'ComingSoon...',
				date:'',
				tag:[''],
				mainthum:'',subthum:'',
				link:'/',
				emergent: false,
			},
			{
				title:'ComingSoon...',
				date:'',
				tag:[''],
				mainthum:'',subthum:'',
				link:'/',
				emergent: false,
			},
		],
	}),
	created: function() {
		this.newshover(0)
	},
	updated: function () {
		this.indexanime()
		this.scrollanime_js()
		this.tweet()
	},
}
</script>

<style>
.mthum-1 {
	background-image: url(../../uploads/news/news_1.png);
	background-position: center;
	object-fit: cover;
}
.sthum-1 {
	background-image: url(../../uploads/news/dmynews_1.png);
	background-position: center;
}
.mthum-2 {
	background-image: url(../../uploads/news/news_2.png);
	background-position: center;
}
.sthum-2 {
	background-image: url(../../uploads/news/dmynews_2.png);
	background-position: center;
}
.mthum-3 {
	background-image: url(../../uploads/news/dmynews_3.png);
	background-position: center;
}
.sthum-3 {
	background-image: url(../../uploads/news/dmynews_3.png);
	background-position: center;
}
.mthum-4 {
	background-image: url(../../uploads/news/dmynews_4.png);
	background-position: center;
}
.sthum-4 {
	background-image: url(../../uploads/news/dmynews_4.png);
	background-position: center;
}

@media screen and (min-aspect-ratio: 1/1) {
	.main-news-window-container {
		width: 55%;
		height: 30vw;
		display: grid;
		grid-template-rows: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% ;
		grid-template-columns: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% ;
	}

	.main-news-window-img-container {
		grid-row: 2 / 16;
		grid-column: 1 / 18;
		width: 100%;
		height: 100%;
		overflow: hidden;
		border: 2px solid #e7b06c;
		object-fit: contain;
		background-size: contain;
	}

	.main-news-window-info {
		background-color: #e7b06c;
		grid-row: 5 / 21;
		grid-column: 2 / 20;
		display: grid;
		grid-template-columns: 2.5% 87% 9% 1.5% ;
		grid-template-rows: 2.5% 69% 26% 2.5%;
	}

	.main-news-window-info-text {
		grid-column: 2 / 4;
		grid-row: 3 / 4;
	}

	.main-news-window-info-tag {
		grid-column: 3 / 4;
		grid-row: 2 / 3;
	}

	.main-news-line {
		margin: 0.5rem 0;
		border-bottom: 0.1vw solid #e7b06c;
	}
}

@media screen and (max-aspect-ratio: 1/1) {
	.main-news-window-container {
		width: 100%;
		height: 60vw;
		display: grid;
		grid-template-rows: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% ;
		grid-template-columns: 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% 5% ;
	}

	.main-news-window-img-container {
		grid-row: 2 / 16;
		grid-column: 1 / 17;
		width: 100%;
		height: 95%;
		overflow: hidden;
		border: 2px solid #e7b06c;
		background-size: contain;
	}

	.main-news-window-info {
		background-color: #e7b06c;
		grid-row: 5 / 21;
		grid-column: 2 / 20;
		display: grid;
		grid-template-columns: 2.5% 83% 9% 1.5% ;
		grid-template-rows: 2.5% 64% 31% 2.5%;
	}

	.main-news-window-info-text {
		grid-column: 2 / 4;
		grid-row: 3 / 4;
	}

	.main-news-window-info-tag {
		grid-column: 3 / 4;
		grid-row: 2 / 3;
	}

	.main-news-line {
		margin: 0.5rem 0;
		border-bottom: 0.1vw solid #e7b06c;
	}
}
</style>