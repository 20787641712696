<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		<article class="article-container">
			<div class="f-ali-items-center">
				<p class="m-right-05rem_dis">2023.12.01</p>
				<div class="tag-info">info</div>
				<div class="tag-event">event</div>
			</div>
			<h3 class="m-bottom-2rem">コミックマーケット103参加情報について</h3>
			<div>
				大変ご無沙汰しております。雨地屋しろーです。<br>
				実に8か月振りのHP更新です。<br>
				サークルを立ち上げたはいいものの引っ越しやら何やらでドタバタしてて気が付いたらかなりの時間が経ってました...<br>
				身の回りも落ち着いてきたのでようやく絵にかける時間を増やせそうです。<br><br><br>
				ここから本題です。<br>
				表題にもある通りコミックマーケット103でスペースを頂くことができました！<br>
				今回は友人たちと「崩壊：スターレイル」の合同本での参加なのでサークル名義は違うものです。<br>
				初めてのコミケ参加なのでいろいろグダると思いますが、コミケにお越しの際、少し立ち寄っていただけますと幸いです！<br>
				ちなみに我々の参加場所は「<span class="co-brown">土-西き15a</span>」となっております。<br>
				(詳細は以下のリンク先からご確認ください！)<br><br>

				<a href="https://portal.circle.ms/Circle/Index/10471271" target="_blank" rel="noopener noreferrer">>サークル詳細</a><br>
				<a href="https://webcatalog-free.circle.ms/Circle/18012095" target="_blank" rel="noopener noreferrer">>コミケWebカタログ</a><br><br><br>

				<h5 class="co-brown">・その他</h5>

				<img style="max-width: 60%" src="../../assets/news/02/01.png"><br>

				↑ロゴのデザインと作成を担当しました。<br>
				我ながらお気に入りのデザインです。<br><br><br>

				<img style="max-width: 80%" src="../../assets/news/02/02.png"><br><br><br>

				↑当日使用予定のポスター兼お品書き<br>
				※白露様のイラストを描いてるのは友人です<br><br>

				本当は専用のHPも作成途中でしたがあまりにもタスクが厳しすぎるので断念<br>
				三か月ぐらいかけてたので本当に悔しい結果です...<br>
				コミケの準備にかかる時間が私の想像を遥かに超えてましたね...<br><br>

				開始前からとんでもないグダグダをかましてますが何とか本やらポストカードの発注は完了しました。<br><br><br><br>
				...危なく書き忘れるとこでした、当日ブースで「ペラ」と「クラーラ」のポストカードの配布を行う予定なのでご興味がある方はぜひお越しください！<br>
				ペラのイラストは友人作です<br><br>

				↓ポストカードサンプル<br>
				<div style="display: flex">
					<img style="max-width: 40%; margin-right: 2vw;" src="../../assets/news/02/03.png"><br><br>
					<img style="max-width: 40%" src="../../assets/news/02/04.png"><br>
				</div>

				<br><br>

				ひとしきり書きたいことも書き終えたのでこのあたりで失礼しようと思います。<br>
				当日お越しの方はよろしくお願いいたします！！
			</div>
			<NewsShare />
		</article>
		<div class="f-jus-space-around m-top-2rem">
			<router-link id="homeopen" to="/news/03">
				<div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
					<span class="bt-typeA-border-inner font-E1">← Next</span>
				</div>
			</router-link>
			<router-link id="homeopen" to="/news/01">
				<div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
					<span class="bt-typeA-border-inner font-E1">Prev →</span>
				</div>
			</router-link>
		</div>
		<div class="f-ali-jus-center m-top-1rem">
			<router-link id="homeopen" to="/news">
				<div class="bt-typeA bt-typeA-border m-rl-auto">
					<span class="bt-typeA-border-inner font-E1">Back To List ></span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import NewsShare from './News_Share.vue'

export default {
	name: 'News_02',
	components: {
		NewsShare,
	},
	data () {
		return {
		breadcrumbs: [
		{
			text: 'Home',
			disabled: false,
			to: '/',
			exact: true,
		},
		{
			text: 'News',
			disabled: false,
			to: '/news',
			exact: true,
		},
		{
			text: 'コミックマーケット103参加情報について',
			disabled: true,
			to: '',
		},
		],
		}
	},
	mounted: function() {
		this.scrollanime_js()
		this.tweet()
	},
}
</script>