var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',{staticClass:"section",attrs:{"id":"Contact"}},[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":">"}}),_vm._m(0),_c('VerticalLine'),_vm._m(1),_c('div',{staticClass:"sa sa--up"},[_c('form',{staticClass:"m-rl-auto m-top-3rem tr-duration-05s",attrs:{"id":"contactform","name":"contactform","onsubmit":"return false;"}},[_c('div',{staticClass:"p-relative m-bottom-2rem_dis m-bottom-1_5rem_mob"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact_index),expression:"contact_index"}],attrs:{"name":"ご用件","type":"text","id":"inquiry-index","required":""},domProps:{"value":(_vm.contact_index)},on:{"input":function($event){if($event.target.composing)return;_vm.contact_index=$event.target.value}}}),_vm._m(2)]),_c('div',{staticClass:"p-relative m-bottom-2rem_dis m-bottom-1_5rem_mob"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact_mail),expression:"contact_mail"}],attrs:{"name":"メールアドレス","type":"text","id":"inquiry-email","required":""},domProps:{"value":(_vm.contact_mail)},on:{"input":function($event){if($event.target.composing)return;_vm.contact_mail=$event.target.value}}}),_vm._m(3)]),_c('div',{staticClass:"p-relative m-bottom-2rem_dis m-bottom-1_5rem_mob"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.contact_message),expression:"contact_message"}],attrs:{"name":"お問合せ内容","id":"inquiry-opinion","rows":"8","required":""},domProps:{"value":(_vm.contact_message)},on:{"input":function($event){if($event.target.composing)return;_vm.contact_message=$event.target.value}}}),_vm._m(4)]),_vm._m(5),_vm._m(6),_vm._m(7)]),_vm._m(8)]),_c('BackToHome')],1),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticClass:"font-E3 text-center"},[_c('span',[_vm._v("Contact")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sa sa--up"},[_c('p',{staticClass:"text-center tr-duration-05s",attrs:{"id":"contactform-guide"}},[_vm._v(" こちらのページから問い合わせ"),_c('span',{staticClass:"dis-only"},[_vm._v("、")]),_c('br',{staticClass:"mob-only"}),_vm._v("連絡をすることができます。"),_c('br'),_c('br',{staticClass:"mob-only"}),_vm._v(" 下記フォーマットにご記入いただき"),_c('span',{staticClass:"dis-only"},[_vm._v("、")]),_c('br',{staticClass:"mob-only"}),_vm._v("内容をご確認のうえ送信してください。"),_c('br')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"name"}},[_c('i',{staticClass:"fas fa-pen"}),_vm._v(" ご用件")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"email"}},[_c('i',{staticClass:"fas fa-envelope"}),_vm._v(" メールアドレス")])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":"message"}},[_c('i',{staticClass:"fas fa-comments"}),_vm._v(" お問合せ内容")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"submit-confirm"}},[_c('div',{staticClass:"bt-typeA bt-typeA-border d-block m-rl-auto"},[_c('button',{staticClass:"bt-typeA-border-inner font-E1 font-J1",attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-paper-plane m-right-05rem"}),_vm._v("送信")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none",attrs:{"id":"submitting"}},[_c('div',{staticClass:"bt-typeA bt-typeA-border d-block m-rl-auto"},[_c('button',{staticClass:"bt-typeA-border-inner font-E1 font-J1",attrs:{"type":"button"}},[_c('i',{staticClass:"fas fa-spinner fa-spin m-right-05rem"}),_c('span',[_vm._v("送信中...")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none",attrs:{"id":"submit-failed"}},[_c('p',{staticClass:"text-center co-error m-bottom-2rem"},[_vm._v("通信に失敗しました。"),_c('br'),_vm._v("お手数ですが、通信状態のよい環境でもう一度送信してください。")]),_c('div',{staticClass:"bt-typeA bt-typeA-border d-block m-rl-auto",attrs:{"id":"submit-confirm-retry"}},[_c('button',{staticClass:"bt-typeA-border-inner font-E1 font-J1",attrs:{"type":"submit"}},[_c('i',{staticClass:"fas fa-paper-plane m-right-05rem"}),_vm._v("再送信")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-none tr-duration-05s opacity-0",attrs:{"id":"submit-success"}},[_c('p',{staticClass:"text-center"},[_vm._v(" お問い合わせありがとうございました。"),_c('br'),_vm._v("お問い合わせ内容の送信が完了しました。 ")])])
}]

export { render, staticRenderFns }