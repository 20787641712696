<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		<h2 class="font-E3 text-center"><span>News</span></h2>
		<VerticalLine />
		<v-card theme="disable" class="bg-none sa sa--up">
			<v-tabs v-model="tab" align-with-title grow>
				<v-tabs-slider color="yellow"></v-tabs-slider>
				<v-tab v-for="item in items" :key="item">
					{{ item }}
				</v-tab>
			</v-tabs>
			<v-tabs-items v-model="tab">

				<v-tab-item>

					<!-- ニュース4 -->
					<router-link to="/news/04">
						<div class="news-column m-bottom-2rem_dis">
							<div class="f-ali-items-center">
								<p class="fs-0_8rem m-right-05rem co-black">2024.01.12</p>
								<div class="tag-diary font-E1">diary</div>
							</div>
							<h3 class="co-black fs-h3nomal">2024年もよろしくお願いします</h3>
						</div>
					</router-link>

				</v-tab-item>
			
				<v-tab-item>

					<!-- ニュース3 -->
					<router-link to="/news/03">
						<div class="news-column m-bottom-2rem_dis">
							<div class="f-ali-items-center">
								<p class="fs-0_8rem m-right-05rem co-black">2023.12.31</p>
								<div class="tag-diary font-E1">diary</div>
							</div>
							<h3 class="co-black fs-h3nomal">冬コミ＆2023年もありがとうございました</h3>
						</div>
					</router-link>

					<!-- ニュース2 -->
					<router-link to="/news/02">
						<div class="news-column m-bottom-2rem_dis">
							<div class="f-ali-items-center">
								<p class="fs-0_8rem m-right-05rem co-black">2023.12.01</p>
								<div class="tag-info font-E1">info</div>
								<div class="tag-event font-E1">event</div>
							</div>
							<h3 class="co-black fs-h3nomal">コミックマーケット103参加情報について</h3>
						</div>
					</router-link>

					<!-- ニュース1 -->
					<router-link to="/news/01">
						<div class="news-column m-bottom-2rem_dis">
							<div class="f-ali-items-center">
								<p class="fs-0_8rem m-right-05rem co-black">2023.04.04</p>
								<div class="tag-info font-E1">info</div>
							</div>
							<h3 class="co-black fs-h3nomal">サークル「ねばーランド.F」活動開始しました</h3>
						</div>
					</router-link>

				</v-tab-item>
			</v-tabs-items>
		</v-card>
	</div>
</template>

<script>
import VerticalLine from '../VerticalLine.vue'

export default {
	name: 'News_Maincontents',
	components: {
		VerticalLine,
	},
	data () {
		return {
			tab: null,
			items: [
			'2024',
			'2023',
		],
		breadcrumbs: [
			{
				text: 'Home',
				disabled: false,
				to: '/',
			},
			{
				text: 'News',
				disabled: false,
				href: '',
			},
			],
		}
	},
	mounted: function() {
		this.scrollanime_js()
	},
}
</script>

<style>
.news-column {
	padding: 1rem 2rem;
	border-radius: 0.2vw;
	margin: 2rem 0.5rem;
	box-shadow: 0.04vw 0.04vw 0.4vw 0.01vw #e7b06c;
}
</style>