<template>
	<div class="f-ali-jus-center m-top-2rem sa sa--up">
		<router-link to="/">
			<div class="bt-typeA bt-typeA-border d-block m-rl-auto">
				<span class="bt-typeA-border-inner font-E1">Back To Home ></span>
			</div>
		</router-link>
	</div>
</template>

<script>
export default {
  name: 'Backtohome',
}
</script>