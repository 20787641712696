<template>
	<div>
		<v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
		<article class="article-container">
			<div class="f-ali-items-center">
				<p class="m-right-05rem_dis">2023.12.31</p>
				<div class="tag-diary">diary</div>
			</div>
			<h3 class="m-bottom-2rem">冬コミ＆2023年もありがとうございました</h3>
			<div>
				こんばんは。雨地屋しろーです。<br>
				今年も残すところわずかとなりましたが、皆様いかがお過ごしでしょうか。<br>
				今年はコミケ初参加の他、プライベートでもいろいろあったのでとにかく忙しい一年でした。<br>
				(6月からの記憶が曖昧になってる...)<br><br>
				前置きが長くなりましたが、先日の冬コミのお礼と感想、その他一年の振り返りみたいなものを書いていこうと思います。<br>
				お時間のある方はよろしければ見ていってください。<br><br><br>

				<h5 class="co-brown">・2023年冬コミ</h5><br><br>

				<img style="max-width: 80%" src="../../assets/news/03/01.png"><br><br><br>

				友人たちと「崩壊:スターレイル」の合同本を作成してサークルとして参加しました！<br>
				サークル初参加どころか、そもそも一般客としても参加したことすらなかったので、
				分からないことだらけでとんでもないミスやトラブルが起きて今しました...<br>
				6,000円ぐらいかけたポスターがこっちの不備で飾れなそうになった時は血の気が引きましたが、
				お隣のサークルさんに助けられて無事に飾ることができました...!!<br>
				皆さん本当にいい方たちで、助けがなかったら我々は悲惨な状態になっていたかもしれません...<br>
				本当に感謝してもしきれないぐらいです。お隣りの皆さんありがとうございました！<br><br>

				そして頒布物のほうですが、合同本とポストカードの両方とも余ることなくお配りすることができました！！<br>
				興味を持っていただけるだけでも嬉しいのにまさか全て刷った分すべてお手に取っていただけるとは...!<br>
				こちらも感謝してもしきれないです。お手に取ってくださった皆様、本当にありがとうございました！！<br>
				メンバー一同より、心より感謝いたします。<br><br><br>

				<h5 class="co-brown">・今年の振り返り</h5><br><br>

				イラストの制作活動に注力しようと今年4月にサークルを立ち上げましたが、残念なことに今年はあまりイラストに時間を割けませんでした...<br>
				主にプライベートの事情なのですが、今年の6月から11月まで引っ越しをしたりもあって相当にドタバタしていました。<br>
				ようやっと状況が落ち着いてきたので来年からまたイラストに注力していこうと思います！<br><br>

				ほぼほぼ更新していないイラスト一覧ページですが、さっきあげたジャベリンの超高解像度版をあげておきました。よかったら観てね！<br>
				それと新しくアーカイブページをオープンしました。過去の活動等を記録していくページにする予定なのでこちらもよろしけば見ていってね！<br><br>
				<router-link to="/archive">>Archive</router-link><br>
				<router-link to="/gallery">>Gallery</router-link><br><br><br>

				長くなりましたがここらへんで失礼しようと思います。<br>
				来年もお絵描き頑張っていこうと思いますのでよろしくお願いいたします！<br><br>

				それでは、良いお年をお過ごしください。<br><br><br><br>

				雨地屋しろー
			</div>
			<NewsShare />
		</article>
		<div class="f-jus-space-around m-top-2rem">
			<router-link id="homeopen" to="/news/02">
				<div class="bt-typeA bt-typeA-border d-block m-rl-auto_mob">
					<span class="bt-typeA-border-inner font-E1">Prev →</span>
				</div>
			</router-link>
		</div>
		<div class="f-ali-jus-center m-top-1rem">
			<router-link id="homeopen" to="/news">
				<div class="bt-typeA bt-typeA-border m-rl-auto">
					<span class="bt-typeA-border-inner font-E1">Back To List ></span>
				</div>
			</router-link>
		</div>
	</div>
</template>

<script>
import NewsShare from './News_Share.vue'

export default {
	name: 'News_02',
	components: {
		NewsShare,
	},
	data () {
		return {
		breadcrumbs: [
		{
			text: 'Home',
			disabled: false,
			to: '/',
			exact: true,
		},
		{
			text: 'News',
			disabled: false,
			to: '/news',
			exact: true,
		},
		{
			text: 'コミックマーケット103参加情報について',
			disabled: true,
			to: '',
		},
		],
		}
	},
	mounted: function() {
		this.scrollanime_js()
		this.tweet()
	},
}
</script>