<template>
	<div>
		<p class="text-center">誠意を持って取り組ませていただきます。</p>
		<div>仮画像</div>
		<p class="text-center">ご依頼はCoconalaと当サイトのお問合せフォームより受け付けております。</p>
		<p class="text-center">是非お気軽にご相談くださいませ。</p>
		<!-- リンクとか -->
		<div class="f-jus-space-around width-50p m-top-2rem m-0auto">
			<a href="https://coconala.com/users/3864504" target="_blank" rel="noopener noreferrer">>Coconala</a>
			<router-link to="/contact">>Contact</router-link>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>

</style>